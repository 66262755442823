import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

import {
  CtaContent as BaseCtaContent,
  ListContent as BaseListContent,
} from '../base.styles'
import ButtonCtaComponent from '../components/ButtonCta'

export const ListContent = styled(BaseListContent)`
  margin-top: 56px;
  display: flex;
  flex-direction: column;

  ${media.up('5xl')} {
    flex-direction: row;
  }
`

export const Step = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
`

export const StepEnumeration = styled(Typography)(
  ({ isLast, theme }) => css`
    ${media.up('5xl')} {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        display: ${isLast ? 'none' : 'block'};
        height: 1px;
        width: 111px;
        top: 50%;
        right: 24px;
        background-color: ${selectTheme('colors.neutral.20')({ theme })};
      }
    }
  `,
)

export const StepTitle = styled(Typography)`
  margin-bottom: 12px;
  width: 122px;
  min-height: 55px;
`

export const StepDescription = styled(Typography)`
  width: 100%;

  ${media.up('5xl')} {
    width: 185px;
    margin-right: 24px;
  }
`

export const CtaContent = styled(BaseCtaContent)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 54px;
  flex-direction: column;
  max-width: none;

  ${media.up('5xl')} {
    margin-top: 60px;
    flex-direction: row;
  }
`

export const CtaTitle = styled(Typography)`
  width: 100%;
  margin-bottom: 16px;

  ${media.up('5xl')} {
    width: 600px;
    margin-bottom: 0;
  }
`

export const ButtonCta = styled(ButtonCtaComponent)`
  max-width: 270px;
`
