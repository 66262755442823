import { bool, object, oneOf, string } from 'prop-types'

import Indicator from '../Indicator'

import { Container, Description, Icon, Title, Wrapper } from './styles'

export const propTypes = {
  description: string,
  enumeration: string,
  icon: object,
  isFirst: bool,
  isFixedWidth: bool,
  isLast: bool,
  shadow: oneOf(['xs', 'sm', 'md', 'lg']),
  title: string,
  variant: oneOf(['dark', 'light']),
}

const Card = ({
  description,
  enumeration,
  icon,
  isFirst,
  isLast,
  shadow = 'lg',
  title,
  variant = 'light',
}) => {
  const cardColors = {
    dark: {
      card: 'neutral.80',
      title: 'neutral.0',
      description: 'neutral.0',
    },
    light: {
      card: 'neutral.0',
      title: 'neutral.90',
      description: 'neutral.60',
    },
  }

  return (
    <Wrapper isEnumerated={!!enumeration}>
      <Indicator
        hideLeftLine={isFirst}
        hideRightLine={isLast}
        enumeration={enumeration}
      />

      <Container
        bgColor={cardColors[variant].card}
        isEnumerated={!!enumeration}
        isLast={isLast}
        shadow={shadow}
      >
        {icon && <Icon {...icon} />}
        <Title
          variant={{
            _: 'bodyMdMedium',
            '3xl': 'bodyXlMedium',
          }}
          color={cardColors[variant].title}
        >
          {title}
        </Title>
        <Description
          variant={{ _: 'bodySmRegular', '3xl': 'bodyMdRegular' }}
          color={cardColors[variant].description}
        >
          {description}
        </Description>
      </Container>
    </Wrapper>
  )
}

Card.propTypes = propTypes

export default Card
