import { arrayOf, object, shape, string } from 'prop-types'

import ButtonCta from '../components/ButtonCta'
import CardList from '../components/CardList'
import Description from '../components/Description'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  TitleRichText: string,
  buttonCta: object,
  description: string,
  labelCta: string,
  steps: arrayOf(
    shape({
      title: string,
      description: string,
      enumeration: string,
      icon: object,
    }),
  ),
  title: string,
}

const Template06 = ({
  buttonCta,
  description,
  steps,
  title,
  TitleRichText,
  ...others
}) => (
  <Wrapper bgColor="neutral.10" {...others}>
    <Title>{TitleRichText || title}</Title>
    <Description>{description}</Description>

    {steps?.length && <CardList variant="enumerated" steps={steps} />}
    {buttonCta && <ButtonCta {...buttonCta} />}
  </Wrapper>
)

Template06.propTypes = propTypes

export default Template06
