import { styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { selectTheme } from '@creditas-ui/utilities'

export const Typography = styled(CreditasTypography)`
  :after {
    content: '';
    display: block;
    width: 32px;
    height: 4px;
    background-color: ${selectTheme('colors.primary.60')};
    margin-top: 8px;
    border-radius: 4px;
  }
`
