import { oneOf } from 'prop-types'

import template01 from './Template01'
import template02 from './Template02'
import template03 from './Template03'
import template04 from './Template04'
import template05 from './Template05'
import template06 from './Template06'
import template07 from './Template07'

const propTypes = {
  variant: oneOf([
    'template01',
    'template02',
    'template03',
    'template04',
    'template05',
    'template06',
    'template07',
  ]),
}

const defaultProps = {
  variant: 'template01',
}

const ELEMENT_VARIANTS = {
  template01,
  template02,
  template03,
  template04,
  template05,
  template06,
  template07,
}

const HowItWorks = ({ variant, ...others }) => {
  const currentVariant = variant ?? defaultProps.variant
  const Element = ELEMENT_VARIANTS[currentVariant]

  return <Element variant={variant} {...others} />
}

HowItWorks.propTypes = propTypes
HowItWorks.defaultProps = defaultProps

export default HowItWorks
