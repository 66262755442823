import { arrayOf, oneOf, shape } from 'prop-types'

import Card, { propTypes as stepPropTypes } from '../Card'

import { Container } from './styles'

const propTypes = {
  cardVariant: oneOf(['dark', 'light']),
  shadow: oneOf(['xs', 'sm', 'md', 'lg']),
  steps: arrayOf(shape(stepPropTypes)),
  variant: oneOf(['default', 'enumerated']),
}

const CardList = ({ cardVariant, shadow, steps, variant = 'default' }) => {
  if (!steps?.length) return null

  const isEnumerated = variant === 'enumerated'

  return (
    <Container totalItems={steps?.length || 4} variant={variant}>
      {steps?.map(({ enumeration, id, ...stepData }, index) => (
        <Card
          key={id}
          enumeration={isEnumerated ? enumeration || index + 1 : undefined}
          isFirst={index === 0}
          isLast={index === steps?.length - 1}
          variant={cardVariant}
          shadow={shadow}
          {...stepData}
        />
      ))}
    </Container>
  )
}

CardList.propTypes = propTypes

export default CardList
