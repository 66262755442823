import { bool, string } from 'prop-types'

import { Container, Line, Number } from './styles'

const propTypes = {
  enumeration: string,
  hideLeftLine: bool,
  hideRightLine: bool,
}

const Indicator = ({ enumeration, hideLeftLine, hideRightLine }) => {
  if (!enumeration) return null

  return (
    <Container>
      <Line show={!hideLeftLine} hideOnMobile />
      <Number variant="bodySmMedium" color="neutral.90" as="span">
        {enumeration}
      </Number>
      <Line post="right" show={!hideRightLine} />
    </Container>
  )
}

Indicator.propTypes = propTypes

export default Indicator
