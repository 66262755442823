import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

const Container = styled.div(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    width: fit-content;
    flex-direction: column;
    height: 100%;
    margin-bottom: 0;
    margin-right: ${selectTheme('spacingRight.9xl')({ theme })};

    ${media.up('3xl')} {
      width: 100%;
      height: fit-content;
      flex-direction: row;
      margin-bottom: ${selectTheme('spacingBottom.12xl')({ theme })};
      margin-right: 0;
    }
  `,
)

const Line = styled.span(
  ({ hideOnMobile = false, show, theme }) => css`
    display: block;
    height: 100%;
    flex: 1;
    width: 1px;
    background-color: ${show
      ? selectTheme('colors.primary.40')({ theme })
      : 'transparent'};

    ${hideOnMobile &&
    `
      flex: 0;
    `}

    ${media.up('3xl')} {
      margin-right: -${selectTheme('spacingLeft.6xl')({ theme })};
      width: 100%;
      height: 1px;
      flex: 1;
    }
  `,
)

const Number = styled(Typography)(
  ({ theme }) => css`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${selectTheme('colors.primary.40')({ theme })};

    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: 0;
    z-index: 2;

    ${media.up('3xl')} {
      margin-left: ${selectTheme('spacingLeft.6xl')({ theme })};
    }
  `,
)

export { Container, Line, Number }
