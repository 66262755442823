import { arrayOf, object, shape, string } from 'prop-types'

import { LazyImage } from 'components/LazyImage'
import RichText from 'components/RichText'

import {
  CtaContent,
  ImageContent,
  InfoContent,
  ListContent,
} from '../base.styles'
import ButtonCta from '../components/ButtonCta'
import CardList from '../components/CardList'
import Figure from '../components/Figure'
import Wrapper from '../components/Wrapper'

import { Grid } from './styles'

const propTypes = {
  TitleRichText: string,
  buttonCta: object,
  image: object,
  steps: arrayOf(
    shape({
      title: string,
      description: string,
    }),
  ),
  title: string,
}

const Template03 = ({
  buttonCta,
  image,
  steps,
  title,
  TitleRichText,
  ...others
}) => {
  const shouldRenderImage = steps?.length <= 3 && image

  return (
    <Wrapper bgColor="neutral.90" {...others}>
      <Grid>
        <InfoContent>
          <RichText
            as="h2"
            color="neutral.0"
            data-testid="HowItWorksTitle"
            variant={{ _: 'headingSmLight', '5xl': 'headingXlLight' }}
          >
            {TitleRichText || title}
          </RichText>
        </InfoContent>

        {steps?.length && (
          <ListContent>
            <CardList cardVariant="dark" shadow="sm" steps={steps} />
          </ListContent>
        )}

        {shouldRenderImage && (
          <ImageContent>
            <Figure data-testid="HowItWorksImage">
              <LazyImage {...image} aspectRatio={0.92485549} />
            </Figure>
          </ImageContent>
        )}

        {buttonCta?.label && (
          <CtaContent data-testid="HowItWorksCta">
            <ButtonCta size="medium" {...buttonCta} />
          </CtaContent>
        )}
      </Grid>
    </Wrapper>
  )
}

Template03.propTypes = propTypes

export default Template03
