import { styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

const Typography = styled(CreditasTypography)`
  margin-bottom: ${selectTheme('spacingBottom.6xl')};

  ${media.up('3xl')} {
    max-width: 495px;
  }
`

export { Typography }
