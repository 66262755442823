import { css, styled } from '@creditas-ui/system'
import { Typography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

import CustomIcon from 'components/CustomIcon'

const Wrapper = styled.li(
  ({ isEnumerated }) => css`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: ${isEnumerated ? 'unset' : '288px'};

    ${media.up('3xl')} {
      flex-direction: column;
    }
  `,
)

const Container = styled.div(
  ({ bgColor, isEnumerated, isLast, shadow, theme }) => css`
    background: ${selectTheme(`colors.${bgColor}`)({ theme })};
    border-radius: ${selectTheme('radius.6xl')({ theme })};
    box-shadow: ${selectTheme(`shadow.${shadow}`)({ theme })};
    padding: ${selectTheme('spacingY.6xl')({ theme })};
    margin-right: 0;
    margin-bottom: ${isEnumerated && !isLast ? '48px' : '0'};
    height: auto;
    flex: 1;

    ${media.up('3xl')} {
      width: 100%;
      margin-bottom: 0;
    }
  `,
)

const Icon = styled(CustomIcon)`
  display: block;
  width: 32px;
  height: 32px;
  margin-bottom: 64px;
`

const Title = styled(Typography)`
  display: block;
  margin-bottom: ${selectTheme('spacingBottom.md')};
`

const Description = styled(Typography)`
  display: block;
`

export { Wrapper, Container, Title, Description, Icon }
