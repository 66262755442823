import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import BlockDivider from 'components/BlockDivider'
import { Grid as CreditasGrid } from 'components/Grid'

export const Grid = styled(CreditasGrid)`
  grid-template-areas:
    '${'infos '.repeat(4)}'
    '${'image '.repeat(4)}'
    '${'list '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'infos '.repeat(8)}'
      '${'image '.repeat(8)}'
      '${'list '.repeat(8)}';
  }

  ${media.up('5xl')} {
    grid-template-areas: '${'infos '.repeat(4)} . ${'list '.repeat(
      3,
    )} ${'image '.repeat(4)}';
  }
`

export const Step = styled.li`
  &:not(:last-of-type) {
    margin-bottom: 32px;
  }
`

export const StepTitle = styled(BlockDivider)`
  margin-bottom: 12px;
`

export const Figure = styled.figure`
  margin-right: -104px;

  ${media.up('5xl')} {
    margin-right: -61px;
    margin-left: 25px;
    margin-top: -95px;
  }

  .gatsby-image-wrapper {
    margin-left: auto;
    width: 387px;
    max-width: 500px;

    ${media.up('5xl')} {
      width: auto;
      max-width: none;
    }
  }
`
