import { arrayOf, object, shape, string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

import { LazyImage } from 'components/LazyImage'
import RichText from 'components/RichText'

import { ImageContent, InfoContent, ListContent } from '../base.styles'
import Wrapper from '../components/Wrapper'

import { Figure, Grid, Step, StepTitle } from './styles'

const propTypes = {
  TitleRichText: string,
  buttonCta: object,
  description: string,
  image: object,
  steps: arrayOf(
    shape({
      title: string,
      description: string,
    }),
  ),
  title: string,
}

const Template01 = ({
  buttonCta,
  description,
  image,
  steps,
  title,
  TitleRichText,
  ...others
}) => {
  return (
    <Wrapper {...others}>
      <Grid>
        <InfoContent>
          <RichText
            as="h2"
            color="neutral.90"
            data-testid="HowItWorksTitle"
            variant={{ _: 'headingSmBold', '5xl': 'headingXlBold' }}
          >
            {TitleRichText || title}
          </RichText>
        </InfoContent>

        {steps?.length && (
          <ListContent as="ul">
            {steps.map((step, index) => (
              <Step key={`step-${index}`}>
                <StepTitle variant="headingSmBold" color="neutral.90">
                  {step.title}
                </StepTitle>
                <Typography variant="bodyXlLight" color="neutral.80">
                  {step.description}
                </Typography>
              </Step>
            ))}
          </ListContent>
        )}

        {image && (
          <ImageContent>
            <Figure data-testid="HowItWorksImage">
              <LazyImage
                {...image}
                aspectRatio={0.618}
                alt={image?.description}
              />
            </Figure>
          </ImageContent>
        )}
      </Grid>
    </Wrapper>
  )
}

Template01.propTypes = propTypes

export default Template01
