import { styled } from '@creditas-ui/system'

const Figure = styled.figure`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: 640px;
  z-index: 0;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

export default Figure
