import { bool, oneOf, string } from 'prop-types'

import { ButtonPrimaryWithArrow } from '@creditas-ui/button'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { Cta } from './styles'

const propTypes = {
  actionHref: string,
  actionType: oneOf(['link', 'scrollTo', 'simulator']),
  label: string,
  openInNewTab: bool,
}

const ButtonCta = ({
  actionHref: url,
  actionType: type,
  label,
  openInNewTab,
  ...others
}) => {
  const { triggerEvent } = useTriggerEvents()

  if (!label) return null

  const handleClick = () => {
    if (!type) return

    const isPageInteraction = type !== 'link'
    triggerEvent({
      label,
      pageInteraction: isPageInteraction,
    })
  }

  const ctaProps = {
    url,
    type,
    openInNewTab,
    ...others,
  }

  return (
    <Cta
      element={ButtonPrimaryWithArrow}
      width="flexible"
      size="large"
      onClick={handleClick}
      analyticEvents={false}
      {...ctaProps}
    >
      {label}
    </Cta>
  )
}

ButtonCta.propTypes = propTypes

export default ButtonCta
