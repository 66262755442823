import { node } from 'prop-types'

import { Typography } from './base.styles'

const propTypes = {
  children: node,
}

const BlockDivider = ({ children, ...others }) => {
  if (!children) return null

  return <Typography {...others}>{children}</Typography>
}
BlockDivider.propTypes = propTypes

export default BlockDivider
