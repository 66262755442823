import { css, styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const containerVariants = {
  default: ({ totalItems }) => css`
    width: 100vw;
    overflow-x: auto;
    margin-left: -20px;
    padding-top: 40px;
    padding-bottom: 40px;
    padding-left: 20px;
    grid-template-rows: max-content;
    grid-template-columns: repeat(
      ${totalItems + 1},
      minmax(min-content, max-content)
    );

    ${media.up('5xl')} {
      padding-bottom: 48px;
    }

    ${media.up('6xl')} {
      width: calc(100vw - ((100vw - 1248px - 32px) / 2));
    }

    &:after {
      content: '';
      display: block;
      min-width: 1px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  `,
  enumerated: ({ totalItems }) => css`
    width: 100%;
    padding: 0;
    margin: 40px 0;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${totalItems}, max-content);

    ${media.up('3xl')} {
      grid-template-rows: max-content;
      grid-template-columns: repeat(${totalItems}, 1fr);
    }
  `,
}

const Container = styled.ul(
  ({ totalItems, variant }) => css`
    ${containerVariants[variant]({ totalItems })}

    list-style: none;
    display: grid;
    grid-column-gap: 24px;

    ${media.up('5xl')} {
      position: relative;
      z-index: 1;
    }
  `,
)

export { Container }
