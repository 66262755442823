import { node, string } from 'prop-types'

import { Container, Section } from './styles'

const propTypes = {
  bgColor: string,
  children: node,
}

const Wrapper = ({ bgColor, children, ...others }) => {
  return (
    <Section bgColor={bgColor} {...others}>
      <Container>{children}</Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes

export default Wrapper
