import { css, styled } from '@creditas-ui/system'
import { Typography as CreditasTypography } from '@creditas-ui/typography'
import { media, selectTheme } from '@creditas-ui/utilities'

const Typography = styled(CreditasTypography)(
  ({ theme }) => css`
    margin-bottom: ${selectTheme('spacingBottom.16xl')({ theme })};

    ${media.up('3xl')} {
      max-width: 392px;
      margin-bottom: ${selectTheme('spacingBottom.18xl')({ theme })};
    }
  `,
)

export { Typography }
