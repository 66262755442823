import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.90',
}

const Description = ({ children, ...others }) => {
  if (!children) return null

  return (
    <Typography
      variant={{ _: 'bodyMdRegular', '3xl': 'bodyXlLight' }}
      color="neutral.80"
      data-testid="description"
      dangerouslySetInnerHTML={{ __html: children }}
      {...others}
    />
  )
}

Description.propTypes = propTypes
Description.defaultProps = defaultProps

export default Description
