import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

const BaseContent = styled.div`
  position: relative;

  ${media.up('5xl')} {
    position: static;
  }
`

export const InfoContent = styled(BaseContent)`
  grid-area: infos;

  ${media.up('5xl')} {
    position: relative;
    z-index: 1;
  }
`

export const ListContent = styled(BaseContent)`
  grid-area: list;
  list-style: none;
`

export const ImageContent = styled(BaseContent)`
  grid-area: image;
`

export const CtaContent = styled(BaseContent)`
  grid-area: cta;
  max-width: 236px;

  ${media.up('5xl')} {
    max-width: none;
  }
`
