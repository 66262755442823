import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'

const Section = styled.section(
  ({ bgColor, theme }) => css`
    position: relative;
    overflow: hidden;
    background-color: ${selectTheme(`colors.${bgColor}`)({ theme })};
  `,
)

const Container = styled(CreditasContainer)`
  position: relative;
  padding-top: ${selectTheme('spacingTop.16xl')};
  padding-bottom: ${selectTheme('spacingBottom.16xl')};

  ${media.up('3xl')} {
    padding-top: ${selectTheme('spacingTop.21xl')};
    padding-bottom: ${selectTheme('spacingTop.21xl')};
  }
`

export { Section, Container }
