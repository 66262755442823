import { arrayOf, object, shape, string } from 'prop-types'

import { Typography } from '@creditas-ui/typography'

import RichText from 'components/RichText'

import { InfoContent } from '../base.styles'
import Wrapper from '../components/Wrapper'

import {
  ButtonCta,
  CtaContent,
  CtaTitle,
  ListContent,
  Step,
  StepDescription,
  StepEnumeration,
  StepTitle,
} from './styles'

const propTypes = {
  TitleRichText: string,
  buttonCta: object,
  description: string,
  descriptionCta: string,
  steps: arrayOf(
    shape({
      title: string,
      description: string,
    }),
  ),
  title: string,
}

const Template02 = ({
  buttonCta,
  description,
  descriptionCta,
  steps,
  title,
  TitleRichText,
  ...others
}) => {
  return (
    <Wrapper {...others}>
      <InfoContent>
        <RichText
          as="h2"
          color="neutral.90"
          data-testid="HowItWorksTitle"
          variant={{ _: 'headingSmBold', '5xl': 'headingXlBold' }}
        >
          {TitleRichText || title}
        </RichText>

        <Typography variant="bodyMdLight">{description}</Typography>
      </InfoContent>

      {steps?.length && (
        <ListContent as="ul">
          {steps.map((step, index) => (
            <Step key={`step-${index}`}>
              <StepEnumeration
                variant="headingXlBold"
                isLast={steps.length === index + 1}
              >
                {step.enumeration}
              </StepEnumeration>
              <StepTitle variant="headingSmBold" color="neutral.90">
                {step.title}
              </StepTitle>
              <StepDescription variant="bodyXlLight" color="neutral.80">
                {step.description}
              </StepDescription>
            </Step>
          ))}
        </ListContent>
      )}

      {descriptionCta && (
        <CtaContent data-testid="HowItWorksCta">
          <CtaTitle
            variant={{ _: 'headingSmBold', '5xl': 'headingLgBold' }}
            data-testid="HowItWorksTitleCta"
          >
            {descriptionCta}
          </CtaTitle>
          <ButtonCta {...buttonCta} />
        </CtaContent>
      )}
    </Wrapper>
  )
}

Template02.propTypes = propTypes

export default Template02
