import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { Cta as CreditasCta } from 'components/Cta'

const Cta = styled(CreditasCta)`
  width: 100%;
  text-decoration: none;
  transition: box-shadow 0.3s ease-in-out;

  ${media.up('3xl')} {
    width: fit-content;
  }

  svg {
    margin-left: 32px;
  }
`

export { Cta }
