import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

import { Grid as CreditasGrid } from 'components/Grid'

export const Grid = styled(CreditasGrid)`
  grid-template-areas:
    '${'infos '.repeat(4)}'
    '${'list '.repeat(4)}'
    '${'cta '.repeat(4)}';

  ${media.up('2xl')} {
    grid-template-areas:
      '${'infos '.repeat(8)}'
      '${'list '.repeat(8)}'
      '${'cta '.repeat(8)}';
  }

  ${media.up('5xl')} {
    padding-top: 24px;
    padding-bottom: 16px;
    grid-template-areas:
      '${'infos '.repeat(8)} . ${'image '.repeat(3)}'
      '${'list '.repeat(8)} . ${'image '.repeat(3)}'
      '${'cta '.repeat(8)} . ${'image '.repeat(3)}';
  }
`
